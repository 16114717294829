@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	font-family: "Poppins", sans-serif;
	background-image: linear-gradient(
		to left bottom,
		#e0eff5,
		#e5f4f5,
		#ebf8f6,
		#f2fbf7,
		#fafffa
	);
	min-height: 100vh;
}
